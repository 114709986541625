export const ON_SALE_PRODUCTS = 'frontend/sales/ON_SALE_PRODUCTS';
export const DEALS_LIVE = 'frontend/sales/DEALS_LIVE';
export const SALE_SPEND = 'frontend/sales/SALE_SPEND';
export const LIVE_SALES = 'frontend/sales/LIVE_SALES';
export const MAJOR_SALE = 'frontend/sales/MAJOR_SALE';

export const initialState = {
  products: {},
  liveSales: [],
  majorSale: {},
  dealsLive: 0,
  saleSpend: {
    orderTotal: 0,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ON_SALE_PRODUCTS:
      return { ...state, products: action.payload };
    case DEALS_LIVE:
      return { ...state, dealsLive: action.payload };
    case SALE_SPEND:
      return { ...state, saleSpend: action.payload };
    case LIVE_SALES:
      return { ...state, liveSales: action.payload };
    case MAJOR_SALE:
      return { ...state, majorSale: action.payload };
    default:
      return state;
  }
}
