const FETCH_CART = 'frontend/cart/FETCH_CART';
const EMPTY_CART = 'frontend/cart/EMPTY_CART';
const ADD_CART = 'frontend/cart/ADD_CART';
const REMOVE_CART = 'frontend/cart/REMOVE_CART';
const REPLACE_CART = 'frontend/cart/REPLACE_CART';
const PRECHECK_PASS = 'frontend/cart/PRECHECK_PASS';
const ADD_ALL = 'frontend/cart/ADD_ALL';
const REMOVE_ALL = 'frontend/cart/REMOVE_ALL';
const SET_REMOVAL_DELAY = 'frontend/cart/SET_REMOVAL_DELAY';
const CANCEL_REMOVE_CART = 'frontend/cart/CANCEL_REMOVE_CART';
const REMOVE_CART_DELAY_CLEANUP = 'frontend/cart/REMOVE_CART_DELAY_CLEANUP';

export default function reducer(state = {
  items: [],
  rawtotal: 0,
  subtotal: 0,
  vat: 0,
  total: 0,
  promoSaving: 0,
  addingLoader: false,
  allSlugs: [],
  dispatched: false,
}, action) {
  switch (action.type) {
    case FETCH_CART:
      if (action.payload.data === 'Could not parse id') return state;
      if (action.payload.data instanceof Array) {
        return {
          ...state,
          items: action.payload.data,
          allSlugs: action.payload.allSlugs,
          dispatched: true,
        };
      }
      return { ...state };
    case ADD_CART:
      if (action.payload === 'Could not parse id') return state;
      if (action.payload.data && action.payload.data instanceof Array) {
        return {
          ...state,
          items: action.payload.data,
          itemAddedId: action.payload.itemAddedId,
          allSlugs: action.payload.allSlugs,
        };
      }
      return { ...state };
    case EMPTY_CART:
      return {
        ...state,
        items: [],
        allSlugs: [],
      };
    case REMOVE_CART:
      if (action.payload === 'Could not parse id') return state;
      if (action.payload.data && action.payload.data instanceof Array) {
        return {
          ...state,
          items: action.payload.data,
          allSlugs: action.payload.allSlugs,
        };
      }
      return { ...state };
    case REPLACE_CART:
      return state;
    case PRECHECK_PASS:
      return state;
    case ADD_ALL:
      if (action.payload === 'Could not parse id') return state;
      if (action.payload.data && action.payload.data instanceof Array) {
        return {
          ...state,
          items: action.payload.data,
          itemAddedId: action.payload.itemAddedId,
          allSlugs: action.payload.allSlugs,
          itemsAddedIds: action.payload.itemsAddedIds,
        };
      }
      return { ...state };
    case REMOVE_ALL:
      if (action.payload === 'Could not parse id') return state;
      if (action.payload.data && action.payload.data instanceof Array) {
        return {
          ...state,
          items: action.payload.data,
          itemAddedId: action.payload.itemAddedId,
          allSlugs: action.payload.allSlugs,
        };
      }
      return { ...state };

    case SET_REMOVAL_DELAY:
      return {
        ...state,
        removalDelays: {
          ...state.removalDelays,
          [action.productId]: {
            timeoutId: action.timeoutId,
            canceled: false,
          },
        },
      };
    case CANCEL_REMOVE_CART:
      if (state.removalDelays[action.productId]) {
        clearTimeout(state.removalDelays[action.productId].timeoutId);
        return {
          ...state,
          removalDelays: {
            ...state.removalDelays,
            [action.productId]: { ...state.removalDelays[action.productId], canceled: true },
          },
        };
      }
      return state;
    case REMOVE_CART_DELAY_CLEANUP: {
      const { [action.productId]: removed, ...remainingDelays } = state.removalDelays;
      return {
        ...state,
        removalDelays: remainingDelays,
      };
    }
    default:
      return state;
  }
}
