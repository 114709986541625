import get from 'lodash/get';

import State from '../Helpers/State';

const pageView = (action) => {
  const userId = State.getUserId();
  return {
    userId,
    hitType: 'Pageview',
    page: get(action, 'payload', ''),
  };
};

const addCart = action => ({
  hitType: 'AddCart',
  productName: get(action, 'payload.productName', ''),
  id: get(action, 'payload.data', ''), // updated items
  itemAddedId: get(action, 'payload.itemAddedId', ''),
  cartBefore: get(action, 'payload.cartBefore', ''),
  userId: get(action, 'payload.userId', ''),
  pageId: get(action, 'payload.pageId', ''),
  isPickAndMix: get(action, 'payload.isPickAndMix'),
});
const removeCart = action => ({
  hitType: 'RemoveCart',
  id: get(action, 'payload.data', ''),
  cartBefore: get(action, 'payload.cartBefore', ''),
  userId: get(action, 'payload.userId', ''),
  pageId: get(action, 'payload.pageId', ''),
  cartBeforeEntire: get(action, 'payload.cartBeforeEntire'),
});

const addMany = action => ({
  hitType: 'AddMany',
  id: get(action, 'payload.data', ''), // updated items
  itemsAddedIds: get(action, 'payload.itemsAddedIds', ''),
  cartBefore: get(action, 'payload.cartBefore', ''),
  userId: get(action, 'payload.userId', ''),
  pageId: get(action, 'payload.pageId', ''),
});

const addVoucher = action => ({
  hitType: 'AddVoucher',
  voucher_entered: get(action, 'payload', ''),
  userId: get(action, 'payload.userId', ''),
  pageId: get(action, 'payload.pageId', ''),
});


const createOrder = action => ({
  hitType: 'CreateOrder',
  transactionId: get(action, 'payload.transactionId'),
  paymentMethod: get(action, 'payload.paymentMethod'),
  transactionTotal: get(action, 'payload.transactionTotal'),
  transactionTax: get(action, 'payload.transactionTax'),
  transactionProducts: get(action, 'payload.transactionProducts'),
  pageId: get(action, 'payload.pageId'),
  discountPercent: get(action, 'payload.discountPercent'),
  newCustomer: get(action, 'payload.newCustomer'),
  roktOptOut: get(action, 'payload.roktOptOut'),
  userId: get(action, 'payload.userId'),
  countryCode: get(action, 'payload.countryCode'),
  track_to_tapfiliate: get(action, 'payload.track_to_tapfiliate'),
});


const completeOrder = action => get(action, 'payload');

const login = action => ({
  hitType: 'Login',
  userId: get(action, 'payload.userId', ''),
  pageId: get(action, 'payload.pageId', ''),
  newsletter: get(action, 'payload.newsletter', false),
});

const logout = action => ({
  hitType: 'Logout',
  userId: get(action, 'payload.userId', ''),
  pageId: get(action, 'payload.pageId', ''),
});

const signUp = action => ({
  hitType: 'Signup',
  userId: get(action, 'payload.userId', ''),
  pageId: get(action, 'payload.pageId', ''),
  newsletter: get(action, 'payload.newsletter', false),
});

const splitTestTrigger = action => ({
  hitType: 'SplitTest',
  experimentName: get(action, 'payload.experimentName', ''),
  testGroup: get(action, 'payload.variant', ''),
});

const newsletterSignup = action => ({
  hitType: 'NewsletterSignup',
  userEmail: get(action, 'payload.email', ''),
  pageId: get(action, 'payload.pageId', ''),
  componentId: get(action, 'payload.context', ''),
});

const newsletterSignupFailed = action => ({
  hitType: 'NewsletterSignupFailed',
  userEmail: get(action, 'payload.email', ''),
  pageId: get(action, 'payload.pageId', ''),
  componentId: get(action, 'payload.context', ''),
  reason: get(action, 'payload.reason', ''),
});

const changeLanguage = action => ({
  hitType: 'ChangeLanguage',
  context: get(action, 'payload.context', ''),
  from: get(action, 'payload.from', ''),
  to: get(action, 'payload.to', ''),
});

const showLanguagePrompt = action => ({
  hitType: 'ShowLanguagePrompt',
  context: get(action, 'payload.context', 'banner'),
  from: get(action, 'payload.from', ''),
  to: get(action, 'payload.to', ''),
});

const showMysteryUpsell = action => ({
  hitType: 'ShowMysteryUpsell',
  product: get(action, 'payload.name', ''),
  _id: get(action, 'payload._id', ''),
});

const changeCurrency = action => ({
  hitType: 'ChangeCurrency',
  from: get(action, 'payload.from', ''),
  to: get(action, 'payload.to', ''),
});

const detectKy = () => ({
  hitType: 'KyDetected',
});

const detectHoney = () => ({
  hitType: 'HoneyDetected',
});

const notFound = () => ({
  hitType: 'notFound',
});

const errorPage = () => ({
  hitType: 'errorPage',
});

const viewStarDeal = () => ({
  hitType: 'viewStarDealExitIntentModal',
});

const ytVideoBroken = action => ({
  hitType: 'ytVideoBroken',
  videoInfo: get(action, 'payload', {}),
});

const productAggregate = action => ({
  hitType: 'productData',
  productData: get(action, 'payload', ''),
});

const ageGateCheck = () => ({
  hitType: 'ageGateAlreadyPassed',
});

const ageGateSuccess = () => ({
  hitType: 'ageGateSuccess',
});

const viewEmailBasket = () => ({
  hitType: 'viewEmailBasketExitIntentModal',
  anonid: State.getAnonId(),
  basketLength: State.getCartItems().length,
});

const submitEmailBasket = () => ({
  hitType: 'submitEmailBasketExitIntentModal',
  anonid: State.getAnonId(),
  basketLength: State.getCartItems().length,
});

const outOfStock = action => ({
  hitType: 'Out of Stock',
  wrong_region: get(action.payload, 'wrong_region', false),
});

const showSignIn = () => ({
  hitType: 'loginView',
  modal: 'signin',
});

const showRegister = () => ({
  hitType: 'loginView',
  modal: 'register',
});

const authLogging = action => ({
  hitType: 'authEvent',
  action: get(action, 'payload.action'),
  value: get(action, 'payload.value'),
});

const viewProductVideo = action => ({
  hitType: 'productVideo',
  type: get(action, 'payload.type'),
  productId: get(action, 'payload.productId'),
  videoId: get(action, 'payload.videoId'),
});

const productViewed = action => ({
  hitType: 'productView',
  productId: get(action.payload, 'productId', ''),
});

const advertViewed = action => ({
  hitType: 'advertView',
  advertId: get(action.payload, 'advertId', ''),
  url: get(action.payload, 'url', ''),
  component: get(action.payload, 'component', ''),
  splitTestName: get(action.payload, 'splitTestName', ''),
  splitTestVariant: get(action.payload, 'splitTestVariant', ''),
  position: get(action.payload, 'index', ''),
});


const advertClicked = action => ({
  hitType: 'advertClick',
  advertId: get(action.payload, 'advertId', ''),
  url: get(action.payload, 'url', ''),
  position: get(action.payload, 'index', ''),
  component: get(action.payload, 'component', ''),
  splitTestName: get(action.payload, 'splitTestName', ''),
  splitTestVariant: get(action.payload, 'splitTestVariant', ''),
});

const cardQuickLook = action => ({
  hitType: 'cardQuickLookOpened',
  modal: 'quicklook',
  productId: get(action.payload, 'productId', ''),
});

const addedToCartFromCheckoutModal = action => ({
  hitType: 'addedToCartFromCheckoutModal',
  modal: 'checkout',
  productId: get(action.payload, 'productId', ''),
});

const addedToWishlistFromCheckoutModal = action => ({
  hitType: 'addedToWishlistFromCheckoutModal',
  modal: 'checkout',
  productId: get(action.payload, 'productId', ''),
});

const viewedFromCheckoutModal = action => ({
  hitType: 'viewedFromCheckoutModal',
  modal: 'checkout',
  productId: get(action.payload, 'productId', ''),
});

const cookieAccept = () => ({
  hitType: 'CookieBotConsent',
  CookieBotOk: true,
});

const webVital = action => ({
  hitType: 'WebVital',
  data: action.payload,
});

const cookieWithdraw = () => ({
  hitType: 'CookieBotConsent',
  CookieBotOk: false,
});

const wishlistInteraction = action => ({
  hitType: 'WishlistInteraction',
  productName: get(action, 'payload.productName', false),
  add: get(action, 'payload.add', false),
  remove: get(action, 'payload.remove', false),
  location: get(action, 'payload.location'),
  componentType: get(action, 'payload.componentType', 'HitCard'),
  productId: get(action, 'payload.productId'),
  isLoggedIn: get(action, 'payload.isLoggedIn'),
});

const themeSwitch = action => ({
  hitType: 'ThemeSwitch',
  siteTheme: get(action, 'payload'),
});

const clickInteraction = action => ({
  hitType: 'clickInteraction',
  click: {
    section: get(action, 'payload.section'),
    value: get(action, 'payload.value'),
  },
});

const fandomExitIntent = action => ({
  hitType: 'fandomExitIntent',
  answer: get(action, 'payload'),
});

const unsubscribePreferenceCentre = () => ({
  hitType: 'unsubscribePreferenceCentre',
});

const selectPaymentMethod = action => ({
  hitType: 'selectPaymentMethod',
  isDefault: get(action, 'payload.isDefault', false),
  method: get(action, 'payload.method', null),
  orderId: get(action, 'payload.orderId', null),
});

const submitPayment = action => ({
  hitType: 'submitPayment',
  method: get(action, 'payload.method', null),
  orderId: get(action, 'payload.orderId', null),
});

const paymentError = action => ({
  hitType: 'paymentError',
  method: get(action, 'payload.error', null),
});

const cannotReviewProduct = action => ({
  hitType: 'cannotReviewProduct',
  productId: get(action, 'payload.productId'),
  slug: get(action, 'payload.slug'),
  reason: get(action, 'payload.reason', null),
  userId: State.getUserId(),
});

const assistantUninstalled = () => ({
  hitType: 'assistantUninstalled',
});

const browserExtensionUninstalled = () => ({
  hitType: 'browserExtensionUninstalled',
});

const wishlistMostWantedSection = action => ({
  hitType: 'wishlistMostWantedSection',
  sectionType: get(action, 'payload.type', ''),
});

const unequalOrder = action => ({
  hitType: 'unequalOrder',
  orderId: get(action, 'payload.orderId', null),
  basketId: get(action, 'payload.basketId', null),
  userId: get(action, 'payload.userId', null),
  cartTotal: get(action, 'payload.cartTotal', null),
  orderTotal: get(action, 'payload.orderTotal', null),
});

const cartUpsell = action => ({
  hitType: 'cartAtCart',
  cartCurrency: get(action, 'payload.cartCurrency', null),
  cartTotal: get(action, 'payload.cartTotal', null),
  cartItems: get(action, 'payload.cartItems', null),
});

const autoSearch = action => ({
  hitType: 'autoSearch',
  reason: action.payload.reason,
  query: action.payload.query,
  nbHits: action.payload.nbHits,
});

const autoSearchResult = action => ({
  hitType: 'autoSearchResult',
  reason: action.payload.reason,
  query: action.payload.query,
  result: action.payload.result,
});

const autoSearchMore = action => ({
  hitType: 'autoSearchMore',
  reason: action.payload.reason,
  query: action.payload.query,
});

const productFinderSearchGames = action => ({
  hitType: 'productFinderSearchGames',
  selections: {
    selectedFacets: get(action, 'payload.selectedFacets', []),
    resultsCount: get(action, 'payload.resultsCount', 0),
  },
});

const freeToPlayClick = action => ({
  hitType: 'freetoplayclick',
  productName: get(action, 'payload.productName', ''),
  productId: get(action, 'payload.productId', ''),
  targetURL: get(action, 'payload.targetURL', ''),
});

const mysteryRevealSkipButton = action => ({
  hitType: 'mysteryRevealSkipButton',
  bundleID: get(action, 'payload.bundleID', ''),
});

export default {
  '@@router/LOCATION_CHANGE': pageView,
  'frontend/cart/ADD_CART': addCart,
  'frontend/cart/ADD_ALL': addMany,
  'frontend/cart/REMOVE_CART': removeCart,
  'frontend/cart/ADD_VOUCHER': addVoucher,
  'frontend/checkout/CREATE_ORDER': createOrder,
  'frontend/receipt/COMPLETE_ORDER': completeOrder,
  'frontend/auth/LOGIN_SUCCESS': login,
  'frontend/auth/LOGOUT': logout,
  'frontend/auth/SIGNUP_SUCCESS': signUp,
  'frontend/split-test/SPLIT_TEST': splitTestTrigger,
  'frontend/email-subscribe/EMAIL_SUBSCRIBE': newsletterSignup,
  'frontend/email-subscribe/EMAIL_ERROR': newsletterSignupFailed,
  'frontend/languages/SHOW_PROMPT': showLanguagePrompt,
  'frontend/mystery/SHOW_UPSELL': showMysteryUpsell,
  'frontend/languages/CHANGE_LANGUAGE': changeLanguage,
  'frontend/native-rates/GET_NATIVE_RATES': changeCurrency,
  'frontend/auth/DETECT_KY': detectKy,
  'frontend/auth/DETECT_HONEY': detectHoney,
  'frontend/products/VIEW_VIDEO': viewProductVideo,
  'frontend/not-found/NOT_FOUND': notFound,
  'frontend/error/ERROR_PAGE': errorPage,
  'frontend/out-of-stock/OUT_OF_STOCK': outOfStock,
  'frontend/auth/SHOW_SIGN_IN': showSignIn,
  'frontend/auth/SHOW_REGISTER': showRegister,
  'frontend/auth/AUTH_LOG': authLogging,
  'frontend/product-viewed/PRODUCT_VIEWED': productViewed,
  'frontend/hit-card/QUICKLOOK_OPENED': cardQuickLook,
  'frontend/checkout-modal/ADDED_TO_CART_FROM_CHECKOUT_MODAL': addedToCartFromCheckoutModal,
  'frontend/checkout-modal/ADDED_TO_WISHLIST_FROM_CHECKOUT_MODAL': addedToWishlistFromCheckoutModal,
  'frontend/checkout-modal/VIEWED_FROM_CHECKOUT_MODAL': viewedFromCheckoutModal,
  'frontend/cookie/COOKIE_CONSENT': cookieAccept,
  'frontend/cookie/COOKIE_RESET': cookieWithdraw,
  'frontend/wishlist/WISHLIST_INTERACTION': wishlistInteraction,
  'frontend/wishlist/WISHLIST_MOST_WANTED_SECTION': wishlistMostWantedSection,
  'frontend/product/PRODUCTS_GROUP_AGGREGATE': productAggregate,
  'frontend/exit-intent/VIEW_EMAIL_BASKET': viewEmailBasket,
  'frontend/exit-intent/VIEW_STAR_DEAL': viewStarDeal,
  'frontend/products/YT_VIDEO_BROKEN': ytVideoBroken,
  'frontend/exit-intent/SUBMIT_EMAIL_BASKET': submitEmailBasket,
  'frontend/exit-intent/SUMBIT_FANDOM_ANSWER': fandomExitIntent,
  'frontend/age-gate/AGE_GATE_CHECK': ageGateCheck,
  'frontend/age-gate/AGE_GATE_SUCCESS': ageGateSuccess,
  'frontend/carousel/VIEWED': advertViewed,
  'frontend/carousel/CLICKED': advertClicked,
  'frontend/site-theme/SWITCH_SITE_THEME': themeSwitch,
  'frontend/click/CLICK_INTERACTION': clickInteraction,
  'frontend/preference/UNSUBSCRIBE': unsubscribePreferenceCentre,
  'frontend/payment/SELECT_PAYMENT_METHOD': selectPaymentMethod,
  'frontend/payment/SUBMIT_PAYMENT': submitPayment,
  'frontend/payment/PAYMENT_ERROR': paymentError,
  'frontend/user-review/CANNOT_REVIEW_PRODUCT': cannotReviewProduct,
  'frontend/assistant-uninstall/ASSISTANT_UNINSTALL': assistantUninstalled,
  'frontend/browser-extension-uninstall/BROWSER_EXTENSION_UNINSTALL': browserExtensionUninstalled,
  'frontend/payment/ORDER_TOTAL_UNEQUAL_VERSUS_CARTCALC': unequalOrder,
  'frontend/autosearch/AUTOSEARCH': autoSearch,
  'frontend/autosearch/AUTOSEARCH_RESULT': autoSearchResult,
  'frontend/autosearch/AUTOSEARCH_MORE': autoSearchMore,
  'frontend/cart/CART_UPSELL': cartUpsell,
  'frontend/product-finder/SEARCH_GAMES': productFinderSearchGames,
  'frontend/mystery-reveal/SKIP_ANIMATION': mysteryRevealSkipButton,
  'frontend/product/FREE_TO_PLAY': freeToPlayClick,
  WEB_VITAL: webVital,
};
