import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import autoRehydrate from 'redux-persist/lib/autoRehydrate';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { createMiddleware } from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import { batchStoreEnhancer, batchMiddleware } from 'redux-batch-enhancer';

import env from '../env';
import countryData from '../Helpers/Geo/data';
import nrWrap from '../Helpers/newrelic';
import eventsMap from './eventsMap';
import rootReducer from './reducer';
import initialDataFetch from './initialize';
import { hasLoaded, hasV3Loaded } from './ducks/recaptcha';
import { amplitudeTarget } from './amplitude';
import amplitudeEventsMap from './amplitudeEventsMap';
import waitForSentry from '../Helpers/Sentry/wait-for-sentry';

let composeEnhancers = compose;

if (env.isDevelopment ||
  env.appUrl === 'staging.fanatical.com' ||
  env.appUrl === 'staging2.fanatical.com' ||
  env.appUrl === 'testing.fanatical.com' ||
  env.appUrl === 'prelive.fanatical.com') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 50,
      shouldHotReload: false,
    }) : compose;
}

const locale = window.locale || 'en';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory({
    basename: `/${locale}`,
  }),
});

const staticReducers = {
  router: routerReducer,
  ...rootReducer,
};

const store = createStore(
  combineReducers(staticReducers),
  composeEnhancers(
    applyMiddleware(
      batchMiddleware,
      thunkMiddleware,
      routerMiddleware,
      createMiddleware(eventsMap, GoogleTagManager()),
      createMiddleware(amplitudeEventsMap, amplitudeTarget),
      /**
       * Capture events and add to Sentry as breadcrumbs
       */
      createMiddleware(eventsMap, (events) => {
        waitForSentry((Sentry) => {
          const state = store.getState();
          Sentry.withScope((scope) => {
            const extra = {
              router: state.router && state.router.location ? state.router.location.pathname : null,
              cartItems: state.cart && state.cart.items ? state.cart.items.length : 0,
              country: countryData.countryCode,
              countryData,
              hasLoggedIn: state.initial ? state.initial.hasLoggedIn : false,
              splitTest: state.splitTest,
              cookies: (document.cookie || '').split(';'),
              siteTheme: state.siteTheme,
              siteBrand: state.siteBrand,
            };

            Object.keys(extra || {}).forEach((key) => {
              scope.setExtra(key, extra[key]);
            });

            if (!state.auth || !state.auth.authenticated) {
              scope.setUser({
                isLoggedIn: false,
                anonid: state.anonymous.id,
              });
            } else {
              scope.setUser({
                isLoggedIn: true,
                id: state.auth._id,
                adminUrl: `https://admin.fanatical.com/en/admin/users/${state.auth._id}`,
                role: state.auth.role,
                created: state.auth.created,
                anonid: state.anonymous.id,
              });
            }
          });

          events.forEach((event) => {
            let eventToPush = event;
            if (event.event === undefined && event.hitType !== undefined) {
              eventToPush = Object.assign({}, event, { event: event.hitType });
            }
            Sentry.addBreadcrumb({
              message: eventToPush.event,
              category: eventToPush.hitType,
              level: 'info',
              data: eventToPush,
            });
          });
        });
      }),
      createMiddleware({
        'frontend/auth/REFRESH_AUTH': action => nrWrap((newrelic) => {
          newrelic.setUserId(action.payload._id);
          newrelic.setCustomAttribute('isLoggedIn', true);
        }),
        'frontend/auth/LOGIN_SUCCESS': action => nrWrap((newrelic) => {
          newrelic.setUserId(action.payload._id);
          newrelic.setCustomAttribute('isLoggedIn', true);
        }),
        'frontend/auth/LOGOUT': () => nrWrap((newrelic) => {
          newrelic.setUserId(null);
          newrelic.setCustomAttribute('isLoggedIn', false);
        }),
        'frontend/split-test/SPLIT_TEST': action => nrWrap((newrelic) => {
          newrelic.setCustomAttribute(`split-${action.payload.experimentName}`, action.payload.testGroup);
        }),
      }),
    ),
    batchStoreEnhancer,
    autoRehydrate(),
  ),
);

// Merge new reducer
function createReducer(asyncReducers) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}

// Add a dictionary to keep track of the registered async reducers
store.asyncReducers = {};

// Create an inject reducer function to add new reducers after initialisation
// This function adds the async reducer, and creates a new combined reducer
store.injectReducer = (key, asyncReducer) => {
  if (!store.asyncReducers[key]) {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  }
};

window.onRecaptchaLoadCallback = () => {
  hasLoaded();
};

window.onRecaptchaLoadCallbackV3 = () => {
  hasV3Loaded();
};

export function getStore() {
  return store;
}

store.dispatch(() => {
  initialDataFetch(store);
});

window.getReduxState = () => store.getState();

export default store;

export const history = createReduxHistory(store);

export function getHistory() {
  return history;
}
